import React from 'react';
import '../index.css';
import { UserOutlined, LockOutlined, LoadingOutlined } from '@ant-design/icons';
import { Typography, Row, Col, Spin, Form, Input, Button, message } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;
const { Title } = Typography;


class NormalLoginForm extends React.Component {

    render() {

        const onFinish = values => {
            this.props.onAuth(values.username, values.password)
                (this.props.isAuthenticated ? this.props.history.push('/result') : this.props.history.push({ pathname: '/login' }))
        };

        const onFinishFailed = errorInfo => {
            console.log('Failed:', errorInfo);
        };

        let errorMessage = null;
        if (this.props.error) {
            errorMessage = (
                <p style={{color: 'red'}}>{`${this.props.error.message} or The username or email / password you’ve entered is incorrect`}</p>
            );
            
            message.error('Login Failed');
        }
        return (
            <div className="login">
                {this.props.token ? this.props.history.push('/result') :
                    <center>
                        <Title level={2}><span className="health">HealthCheck</span></Title><br />
                        <div>
                            {errorMessage}
                            {
                                this.props.loading ?
                                    <Spin indicator={antIcon} />

                                    :

                                    <Form
                                        name="normal_login"
                                        className="login-form"
                                        initialValues={{
                                            remember: false,
                                        }}
                                        onFinish={onFinish}
                                        onFinishFailed={onFinishFailed}
                                    >

                                        <Row justify="center">
                                            <Col span={12}>
                                                <Form.Item
                                                    height="50px"
                                                    name="username"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Username!',
                                                        },
                                                    ]}
                                                >
                                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Enter your username" />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <Row justify="center">
                                            <Col span={12}>
                                                <Form.Item
                                                    height="50px"
                                                    name="password"
                                                    rules={[
                                                        {
                                                            required: true,
                                                            message: 'Please input your Password!',
                                                        },
                                                    ]}
                                                >
                                                    <Input.Password prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Enter your password" />
                                                </Form.Item>
                                            </Col>
                                        </Row>

                                        <br /><br /><br />

                                        <Row justify="center">
                                            <Col span={12}>
                                                <Form.Item>
                                                    <Button type="primary" htmlType="submit" style={{ width: '100%' }} className="login-form-button">
                                                        Log In
                                                    </Button>
                                                    {/* <br /><br />
                                                    <small>
                                                        <Link to="/forgot-password">
                                                            <p className="login-form-forgot">
                                                                Forgot Password?
                                                            </p>
                                                        </Link>
                                                        <br /><br />
                                                    </small> */}
                                                </Form.Item>
                                            </Col>
                                        </Row>
                                    </Form>
                            }
                        </div>
                    </center>
                }
            </div>
        );

    }
}



const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
        isAuthenticated: state.token !== null,
        token: state.token
    }
}
const mapDispatchToProps = dispatch => {
    return {
        onAuth: (username, password) => dispatch(actions.authLogin(username, password))
    }
}
export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NormalLoginForm));