import React from 'react';
import { Route, Switch } from 'react-router-dom';
import NormalLoginForm from './containers/UserLogin';
import ForgetForm from './containers/UserForgetPass';
import Error404 from './containers/Error404';
import ResultPage from './containers/ResultPage';
import ChangePW from './containers/ChangePW';
import ViewUserPatientsDetails from './containers/ViewUserPatientsDetails';
import Register from './containers/Register';

const BaseRouter = () => (
    <div>
        <Switch>
            <Route exact path='/ViewUserPatientsDetails/:id' component={ViewUserPatientsDetails} />
            <Route exact path='/result' component={ResultPage} />
            <Route exact path='/change-password/' component={ChangePW} />
        </Switch>
    </div>
);
export const LoginRouter = () => (
    <div>
        <Switch>
            <Route exact path='/' component={NormalLoginForm} />
            <Route exact path='/login/' component={NormalLoginForm} />
            <Route exact path='/forgot-password/' component={ForgetForm} />
            <Route exact path='/404/' component={Error404} />
            <Route exact path='/register/' component={Register} />
        </Switch>


    </div>

);

export default BaseRouter;