import React from 'react';
import { Layout, Menu, Image} from 'antd';
import { connect } from 'react-redux';
import * as actions from '../store/actions/auth';
import {
    PoweroffOutlined,
    KeyOutlined,
} from '@ant-design/icons';
import 'bootstrap/dist/css/bootstrap.min.css';
import logo from '../containers/img/ic_icon.png';
import service_offer from '../containers/img/Screen-Shot-2019-11-26-at-1.47.32-PM-300x169.png';
import { Link } from 'react-router-dom';

const { Header, Content, Footer } = Layout;

class CustomLayout extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            visible: false
        };
    }
    showDrawer() {
        this.setState({
            visible: true,
        });
    };

    onClose() {
        this.setState({
            visible: false,
        });
    };

    render() {
        if (this.props.isAuthenticated) {
            return (
                <Layout className="layout">
                    <Header style={{ position: 'fixed', zIndex: 1, width: '100%' }} >
                        <div className="logo" />
                        <Menu theme="dark" mode="horizontal">
                            {/* <Menu.Item key="1">
                                <Link to="/change-password/">
                                    <span style={{ color: 'white'}}>
                                        <KeyOutlined />Change Password
                                    </span>
                                </Link>
                            </Menu.Item> */}

                            <Menu.Item key="2" onClick={this.props.logout}>
                                <Link to="/login">
                                    <span style={{ color: 'white'}}>
                                        <PoweroffOutlined />
                                        Logout
                                    </span>
                                </Link>
                            </Menu.Item>
                        </Menu>
                    </Header>
                    <Content style={{ marginTop: '60px' }}> 	
                        <div className="site-layout-content">
                            <div class="container-fluid">
                                <div class="row">
                                    <div class="col-md-12">
                                        <br></br>
                                        <a href="https://healthcheckcdo.com/" class="custom-logo-link" rel="home" aria-current="page">
                                            <Image
                                                width={100}
                                                src={logo}
                                            />
                                        </a>
                                        <br></br>
                                        <span>HealthCheck Clinic and Diagnostic Center</span>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-md-9">
                                        {this.props.children}
                                    </div>
                                    <div class="col-md-3">
                                        <center>
                                            <Image
                                                width={280}
                                                src={service_offer}
                                                
                                            />
                                            
                                        </center>
                                        <br></br>
                                        <span><b>Address</b>: Uno Bldg., A. Velez Street
                                            Cagayan de Oro City 
                                        </span>
                                        <br></br>
                                        <span><b>Contact Number</b>: 09173187661 / 09177035825 /<br></br> 0888521096 / 0883231471 
                                        </span>
                                    </div>
                                </div>               
                            </div>     
                        </div>
                    </Content>
                    <Footer style={{ textAlign: 'center' }}>HealthCheck © 2021</Footer>
                </Layout>
            );
        } else {
            return (
                <div />
            );
        }
    }
}

const mapDispatchToProps = dispatch => {
    return {
        logout: () => dispatch(actions.logout())
    }
}


export default connect(null, mapDispatchToProps)(CustomLayout);