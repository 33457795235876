import React, { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import { PageHeader, Descriptions } from 'antd';
import {apiUrl} from '../config';

function ViewUserPatientsDetails(props){
    let history = useHistory()
    const id = props.match.params.id

    const [patient, setPatient] = useState([])
    const [username, setUsername] = useState('')
    const [file_name, setFilename] = useState('')
    const [attachement, setAttachment] = useState('')

    useEffect(() => {
        fetch(`${apiUrl}/api/healthcheck/resultpage/${id}/`, {
            'method': 'GET',
            headers:{
                'Content-Type': 'application/json',
                'Authorization': `Token ${props.token}`
            }
        })
        .then(reps => reps.json())
        .then(reps => setPatient(reps))
        .catch(error => console.log(error))
    }, [])

    useEffect(() => {
        setUsername(patient.username)
        setFilename(patient.file_name)
        setAttachment(patient.certificate_and_result)
    })
    
    return (
        <div>
            <div>
                <div className="container-fluid">
                    <PageHeader
                            className="site-page-header"
                            onBack={() => history.push(`/result/`)}
                            title="Patient View"
                            subTitle="Detail"
                        />
                        <br />
                        <Descriptions title={`Patient # ${id}`} bordered>
                            <Descriptions.Item label="Username"> {username} </Descriptions.Item>
                            <Descriptions.Item label="Attachment file"> <a href={attachement} target="_blank">{file_name}</a> </Descriptions.Item>
                        </Descriptions>
                </div> 
            </div>
        </div>
    )
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.token !== null,
        token: state.token
    }
}

export default withRouter(connect(mapStateToProps, null)(ViewUserPatientsDetails));