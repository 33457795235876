import React from 'react';
import axios from 'axios';
import { Table, Space, Button, Input } from 'antd';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { DownloadOutlined, EyeOutlined, SearchOutlined } from '@ant-design/icons';
import Highlighter from "react-highlight-words";
import {apiUrl} from '../config';
import moment from 'moment';

class ResultPage extends React.Component {
    ViewPatient = (id) => {
        this.props.history.push(`/ViewUserPatientsDetails/${id}/`);
    };
    constructor(props) {
        super(props);
        this.state = {
            result: [],
            contact_num: [],
            searchText: '',
            searchedColumn: '',
        };
    }
    
    getColumnSearchProps = dataIndex => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
          <div style={{ padding: 8 }}>
            <Input
              ref={node => {
                this.searchInput = node;
              }}
              placeholder={`Search ${dataIndex}`}
              value={selectedKeys[0]}
              onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
              onPressEnter={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
              style={{ width: 188, marginBottom: 8, display: 'block' }}
            />
            <Space>
              <Button
                type="primary"
                onClick={() => this.handleSearch(selectedKeys, confirm, dataIndex)}
                icon={<SearchOutlined />}
                size="small"
                style={{ width: 90 }}
              >
                Search
              </Button>
              <Button onClick={() => this.handleReset(clearFilters)} size="small" style={{ width: 90 }}>
                Reset
              </Button>
              <Button
                type="link"
                size="small"
                onClick={() => {
                  confirm({ closeDropdown: false });
                  this.setState({
                    searchText: selectedKeys[0],
                    searchedColumn: dataIndex,
                  });
                }}
              >
                Filter
              </Button>
            </Space>
          </div>
        ),
        filterIcon: filtered => <SearchOutlined style={{ color: filtered ? '#1890ff' : undefined }} />,
        onFilter: (value, record) =>
          record[dataIndex]
            ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase())
            : '',
        onFilterDropdownVisibleChange: visible => {
          if (visible) {
            setTimeout(() => this.searchInput.select(), 100);
          }
        },
        render: text =>
          this.state.searchedColumn === dataIndex ? (
            <Highlighter
              highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
              searchWords={[this.state.searchText]}
              autoEscape
              textToHighlight={text ? text.toString() : ''}
            />
          ) : (
            text
          ),
    });

    handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        this.setState({
          searchText: selectedKeys[0],
          searchedColumn: dataIndex,
        });
    };
    
    handleReset = clearFilters => {
        clearFilters();
        this.setState({ searchText: '' });
    };

    async componentDidMount() {

        this.interval = await setInterval(() =>
            axios.all([
                axios.get(`${apiUrl}/resultpage/`, {
                    headers: {
                        Authorization: 'Token ' + this.props.token
                    }
                }),
                axios.get(`${apiUrl}/api/healthcheck/contactnum/`, {
                  headers: {
                      Authorization: 'Token ' + this.props.token
                  }
              }),
            ])
                .then(axios.spread((...responses) => {
                    this.setState({
                        result: responses[0].data.filter(item => item.username === localStorage.getItem("username")),
                        contact_num: responses[1].data,
                    })
                }))
            , 5000);

    }
    componentWillUnmount() {
        clearInterval(this.interval);
    }

    render() {
        const columns = [
            {
                title: 'Name',
                dataIndex: 'username',
                key: 'username',
            },
            {
                title: 'Certificate and result',
                dataIndex: 'file_name',
                key: 'file_name',
                ...this.getColumnSearchProps('file_name'),
            },
            {
                title: 'Date and Time',
                dataIndex: 'updated_at',
                key: 'updated_at',
                sorter: (a, b) => moment(a.updated_at) - moment(b.updated_at),
                sortDirections: ['descend', 'ascend'],
                render: (text) => moment(text).format('lll')
            },
            {
              title: 'Contact #',
              dataIndex: 'username',
              key: 'username',
              render: (text) =>
                  <span>
                      {this.state.contact_num.filter(ContactNum => String(ContactNum.user) === String(text)).map((ContactNum) => { return ContactNum.phone_number })}
                  </span>
            },
            {
                title: 'Action',
                dataIndex: 'action',
                key: 'action',
                render: (action: any, record: {id: number}) => {
                    return (
                        <>
                            <Space>
                                <Button type="primary" icon={<EyeOutlined />} size="large" onClick={() => this.ViewPatient(record.id)}>
                                    View Detail
                                </Button>
                                <a href={record.certificate_and_result} target="_blank" rel="noreferrer noopener">
                                    <Button type="primary" icon={<DownloadOutlined />} size="large" />
                                </a>
                            </Space>
                        </>
                    );
                }
            },
            
        ]
        return (
            <div style={{ padding: '35px' }}>
                <h2>Certificate and Result</h2>
                <br />
                <Table dataSource={this.state.result} tableLayout="auto" pagination={{ position: ['topLeft'], pageSize: 10 }} size="small" columns={columns} />
            </div>

        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.loading,
        error: state.error,
        username: state.username,
        isAuthenticated: state.token !== null,
        token: state.token
    }
}

export default withRouter(connect(mapStateToProps, null)(ResultPage));