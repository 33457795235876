import React from 'react';
import '../index.css';
import { Form, Input, Button } from 'antd';
import logo from '../img/logo.PNG'
import { UserOutlined, MailOutlined } from '@ant-design/icons';
import { Typography, Row, Col } from 'antd';
import { Link } from 'react-router-dom';
const { Title } = Typography;

const ForgetForm = () => {
    const onFinish = values => {
        console.log('Received values of form: ', values);
    };
    return (
        <body class="login">
            <div>
                <center>
                    <br />
                    <img src={logo} width="200px" alt="" />
                    <Title level={2}><span className="health">HealthCheck</span></Title>
                    <h6>
                        <span class="health">Forgot </span><span class="management">Password</span>
                    </h6>
                    <Form
                        name="normal_login"
                        className="login-form"
                        initialValues={{
                            remember: true,
                        }}
                        onFinish={onFinish}
                    >
                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item
                                    height="50px"
                                    name="username"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Username!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<UserOutlined className="site-form-item-icon" />} placeholder="Enter your username" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item
                                    height="50px"
                                    name="email"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your Email Address!',
                                        },
                                    ]}
                                >
                                    <Input prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Enter your email address" />
                                </Form.Item>
                            </Col>
                        </Row>
                        <br /><br /><br />
                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item>
                                    <Link to="/login/">
                                        <Button type="primary" className="login-form-button" htmlType="submit" style={{ width: '100%' }}
                                            message="A link to reset your password has been sent to your email!">
                                            S U B M I T
                                        </Button>
                                    </Link>
                                    <br /><br />
                                    <small>
                                        <Link to="/login">
                                            <p className="login-form-forgot">
                                                Log In
                                            </p>
                                        </Link>
                                        <br /><br />
                                    </small>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </center>
            </div>
        </body >
    );
}
export default ForgetForm;