import React from 'react';
import '../index.css';
import { Form, Input, Button, message, Tooltip } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { Typography, Row, Col } from 'antd';
import axios from 'axios';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import {apiUrl} from '../config';

const { Title } = Typography;

class ChangePW extends React.Component {

    render() {

        const onFinish = values => {
            console.log('Received values of form: ', values);

            return axios.post(`${apiUrl}/rest-auth/password/change/`, {
                new_password1: values.newpassword,
                new_password2: values.confirmpass,
                old_password: values.oldpass
            }, {
                headers: {
                    Authorization: 'Token ' + this.props.token
                }
            })
                .then(res => sucMsg(res.data.detail))
                .catch((err) => errMsg(err.response.data));

        };
        const sucMsg = res => {
            message.success(res)
            this.props.history.push('/event/');
            this.forceUpdate();
        }
        const errMsg = err => {

            let errName = Object.getOwnPropertyNames(err)
            // console.log(err.errName[0)
            console.log(errName[0])


            switch (errName[0]) {
                case 'old_password':
                    console.log("old")
                    for (let i = 0; i < err.old_password.length; i++) {
                        message.warning("Current password: " + err.old_password[i])
                    }
                    break;

                case 'new_password2':
                    console.log("new")
                    for (let i = 0; i < err.new_password2.length; i++) {
                        message.warning("New password: " + err.new_password2[i])
                    }
                    break;
                default:
                    break;
            };

        }
        return (
            <div className="login" >
                <br /><br /><br /><br />
                <center>
                    <Title level={2}><span className="health">HealthCheck</span></Title>
                    <h6>
                        <span className="health">Change </span><span className="management">Password</span>
                    </h6>
                    <Form
                        name="change_pw"
                        onFinish={onFinish}
                    >
                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item
                                    height="50px"
                                    name="oldpass"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your current password!',
                                        },
                                    ]}
                                >
                                    <Input.Password id="oldpass" name="oldpass" prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Enter your current password" required />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item
                                    height="50px"
                                    name="newpassword"
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please input your new password!',
                                        },
                                    ]}
                                // help="Should be at least 8 alphanumeric characters"
                                >
                                    <Input.Password id="newpassword" name="newpassword" minLength="8"
                                        prefix={
                                            <Tooltip title="Password should be at least 8 alphanumeric characters." placement="top">
                                                <LockOutlined className="site-form-item-icon" />
                                            </Tooltip>
                                        }
                                        type="password" pattern=".{8,}" required title="Should be at least 8 alphanumeric characters" placeholder="Enter your new password" required />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row justify="center">
                            <Col span={12}>
                                <Form.Item
                                    height="50px"
                                    name="confirmpass"
                                    dependencies={['newpassword']}
                                    rules={[
                                        {
                                            required: true,
                                            message: 'Please confirm your password!',
                                        },
                                        ({ getFieldValue }) => ({
                                            validator(rule, value) {
                                                if (!value || getFieldValue('newpassword') === value) {
                                                    return Promise.resolve();
                                                }
                                                return Promise.reject('The two passwords that you entered do not match!');
                                            },
                                        }),
                                    ]}
                                >
                                    <Input.Password id="confirmpass" name="confirmpass" minLength="8"
                                        prefix={
                                            <Tooltip title="Password should be at least 8 alphanumeric character and must match with your previous input." placement="top">
                                                <LockOutlined className="site-form-item-icon" />
                                            </Tooltip>
                                        }
                                        type="password" placeholder="Confirm your new password" required />
                                </Form.Item>
                            </Col>
                        </Row>
                        <br /><br /><br />
                        <Row justify="center">
                            <Col>
                                <Form.Item>
                                    <Button type="primary" htmlType="submit" className="login-form-button"
                                        style={{ width: '100%', letterSpacing: '3px' }}>
                                        CHANGE PASSWORD
                                        </Button>
                                    <br /><br />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </center>
            </div>
        );
    }
}

const mapStateToProps = (state) => {
    return {
        isAuthenticated: state.token !== null,
        token: state.token
    }
}

export default withRouter(connect(mapStateToProps, null)(ChangePW));